// import { BecomeMentor } from "../../components/BecomeMentor";
import { MentorForm } from "../../components/MentorForm";
// import { Footer } from "../../components/Footer";

const Mentor = () => {
    return(
        <MentorForm/>
    )
}

export default Mentor;