import { LMS } from "../../components/LMS";
import { Footer } from "../../components/Footer";


const Learning = () => {
    return(
        <>
            <LMS />
            <Footer />
        </>
    )
}

export default Learning;