import { CoursesPage } from "../../components/CoursesPage";
import { Footer } from "../../components/Footer";


const Courses = () => {
    return(
        <>
            <CoursesPage />
            <Footer />
        </>
        
    )
}

export default Courses;