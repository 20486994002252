import { Tasks } from "../../components/Tasks";
import { Footer } from "../../components/Footer";


const Taskify = () => {
    return(
        <>
            <Tasks />
            <Footer />
        </>
    )
}

export default Taskify;