import { BecomeMentee } from "../../components/BecomeMentee";
import { Footer } from "../../components/Footer";


const Mentee = () => {
    return(
        <div className="">
           <BecomeMentee />
            <Footer />
        </div>
    )
}

export default Mentee;